import React, { useState } from 'react';
import { Form, Input, Button, Select, DatePicker, InputNumber, Steps, message } from 'antd';
import moment from 'moment';

const { Step } = Steps;
const { Option } = Select;

const paymentMethodOptions = [
  { label: 'Telegraphic Transfer', value: 'TT' },
  { label: 'Letter of Credit', value: 'LC' },
  // Add more payment methods as needed
];

const CreateLCForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const next = () => {
    form.validateFields().then(values => {
      setFormData({ ...formData, ...values });
      setCurrentStep(currentStep + 1);
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFinish = (values) => {
    const combinedValues = {
      ...formData,
      ...values,
      issuedDate: formData.issuedDate ? formData.issuedDate.toISOString() : undefined,
      expiryDate: values.expiryDate ? values.expiryDate.toISOString() : undefined,
    };
    onSubmit(combinedValues);
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Form.Item name="LCNumber" label="LC Number" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="vendor" label="Vendor" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="issuedDate" label="Issued Date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="paymentMethod" label="Payment Method" rules={[{ required: true }]}>
            <Select>
              {paymentMethodOptions.map(option => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Details',
      content: (
        <>
          <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="expiryDate" label="Expiry Date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="issuingBank" label="Issuing Bank" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="authorizedRepresentative" label="Authorized Representative">
            <Input />
          </Form.Item>
          <Form.Item name="beneficiary" label="Beneficiary">
            <Input />
          </Form.Item>
          <Form.Item name="terms" label="Terms" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <Form form={form} layout="vertical" onFinish={handleFinish} style={{ marginTop: 20 }}>
        {steps[currentStep].content}
        <div className="steps-action">
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? 'Creating...' : 'Submit'}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateLCForm;
