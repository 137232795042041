import React from 'react';
import { InputNumber, DatePicker, Input } from 'antd';
import moment from 'moment';

const LGModalContent = ({ actionType, newAmount, setNewAmount, newDate, setNewDate, data }) => {
  const renderContent = () => {
    switch (actionType) {
      case 'increaseAmount':
      case 'reduceAmount':
        return (
          <InputNumber
            min={0}
            defaultValue={newAmount}
            onChange={setNewAmount}
            style={{ width: '100%' }}
          />
        );
      case 'extendDate':
      case 'renewLG':
        return (
          <DatePicker
            onChange={(date) => setNewDate(date)}
            style={{ width: '100%' }}
          />
        );
      case 'updateBeneficiary':
        return (
          <Input
            defaultValue={data.beneficiary || ''}
            onChange={(e) => setNewDate(e.target.value)}
            style={{ width: '100%' }}
          />
        );
      case 'amendLGTerms':
        return (
          <Input.TextArea
            defaultValue={data.terms || ''}
            onChange={(e) => setNewDate(e.target.value)}
            style={{ width: '100%' }}
          />
        );
      case 'transferLG':
        // Implement transfer LG specific logic here
        return null;
      case 'releaseLG':
        // Implement release LG specific logic here
        return null;
      case 'cancel':
        return <p>Are you sure you want to cancel this Letter of Guarantee?</p>;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default LGModalContent;
