// src/components/LoanFields.js
import React from 'react';
import { Form, Input, InputNumber, DatePicker, Row, Col, Card } from 'antd';
import moment from 'moment';

const LoanFields = ({ data, isEditing, onFieldChange }) => {
  const handleFieldChange = (field, value) => {
    onFieldChange(field, value);
  };

  const renderField = (label, value, type, field) => {
    switch (type) {
      case 'number':
        return isEditing ? (
          <InputNumber
            min={0}
            defaultValue={value}
            onChange={(value) => handleFieldChange(field, value)}
            style={{ width: '100%' }}
          />
        ) : (
          value
        );
      case 'date':
        return isEditing ? (
          <DatePicker
            defaultValue={moment(value)}
            onChange={(date) => handleFieldChange(field, date.toISOString())}
            style={{ width: '100%' }}
          />
        ) : (
          moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : 'Invalid Date'
        );
      case 'text':
      default:
        return isEditing ? (
          <Input
            defaultValue={value}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          value
        );
    }
  };

  return (
    <Card bordered={false} style={{ boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', marginBottom: '24px' }}>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="System Code"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('System Code', data.systemCode, 'text', 'systemCode')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Bank"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Bank', data.bank, 'text', 'bank')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="English Description"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('English Description', data.englishDescription, 'text', 'englishDescription')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Arabic Description"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Arabic Description', data.arabicDescription, 'text', 'arabicDescription')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Loan Issue Date"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Loan Issue Date', data.loanIssueDate, 'date', 'loanIssueDate')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Loan Type"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Loan Type', data.loanType, 'text', 'loanType')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Bank Facility"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Bank Facility', data.bankFacility, 'text', 'bankFacility')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="First Due Date"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('First Due Date', data.firstDueDate, 'date', 'firstDueDate')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Vendor"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Vendor', data.vendor, 'text', 'vendor')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Number Of Years"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Number Of Years', data.numberOfYears, 'number', 'numberOfYears')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Payment Amount"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Payment Amount', data.paymentAmount, 'number', 'paymentAmount')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Interest Rate"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Interest Rate', data.interestRate, 'number', 'interestRate')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Sabor Rate"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Sabor Rate', data.saborRate, 'number', 'saborRate')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Loan Reference"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Loan Reference', data.loanReference, 'text', 'loanReference')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Present Value"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Present Value', data.presentValue, 'number', 'presentValue')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Loan Status"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Loan Status', data.loanStatus, 'text', 'loanStatus')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Effective Annual Interest Rate"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Effective Annual Interest Rate', data.effectiveAnnualInterestRate, 'number', 'effectiveAnnualInterestRate')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Facility Type"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Facility Type', data.facilityType, 'text', 'facilityType')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Final Due Date"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Final Due Date', data.finalDueDate, 'date', 'finalDueDate')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Project"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Project', data.project, 'text', 'project')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Number of Payments Per Year"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Number of Payments Per Year', data.numberOfPaymentsPerYear, 'number', 'numberOfPaymentsPerYear')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Re-Payment Amount"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Re-Payment Amount', data.rePaymentAmount, 'number', 'rePaymentAmount')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Down Payment"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Down Payment', data.downPayment, 'number', 'downPayment')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Effective Monthly Interest Rate"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Effective Monthly Interest Rate', data.effectiveMonthlyInterestRate, 'number', 'effectiveMonthlyInterestRate')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Remaining Value"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Remaining Value', data.remainingValue, 'number', 'remainingValue')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Total Amount"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Total Amount', data.amount, 'number', 'totalAmount')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Created At"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {moment(data.createdAt).format('YYYY-MM-DD')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Updated At"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {moment(data.updatedAt).format('YYYY-MM-DD')}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default LoanFields;
