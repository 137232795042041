// src/components/LCModalContent.js
import React from 'react';
import { InputNumber, DatePicker, Input } from 'antd';
import moment from 'moment';

const LCModalContent = ({ actionType, newAmount, setNewAmount, newDate, setNewDate, data }) => {
  const renderContent = () => {
    switch (actionType) {
      case 'increaseAmount':
      case 'reduceAmount':
        return (
          <InputNumber
            min={0}
            defaultValue={newAmount}
            onChange={setNewAmount}
            style={{ width: '100%' }}
          />
        );
      case 'extendDate':
      case 'renewLC':
        return (
          <DatePicker
            // defaultValue={moment(newDate)}
            onChange={(date) => setNewDate(date.toISOString())}
            style={{ width: '100%' }}
          />
        );
      case 'updateBeneficiary':
        return (
          <Input
            defaultValue={data.beneficiary || ''}
            onChange={(e) => setNewDate(e.target.value)}
            style={{ width: '100%' }}
          />
        );
      case 'amendLCTerms':
        return (
          <Input.TextArea
            defaultValue={data.terms || ''}
            onChange={(e) => setNewDate(e.target.value)}
            style={{ width: '100%' }}
          />
        );
      case 'transferLC':
        // Implement transfer LC specific logic here
        return null;
      case 'releaseLC':
        // Implement release LC specific logic here
        return null;
      case 'cancel':
        return <p>Are you sure you want to cancel this Letter of Credit?</p>;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default LCModalContent;