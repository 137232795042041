// src/components/LoanFooterActions.js
import React, { useState } from 'react';
import { Button, Modal, message, Space, DatePicker } from 'antd';
import { useUpdateLoan } from '../../hooks/useLoan';
import moment from 'moment';
import LoanModalContent from './LoanModalContent';

const LoanFooterActions = ({ id, data, revalidate }) => {
  const { update } = useUpdateLoan();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState('');
  const [newAmount, setNewAmount] = useState(null);
  const [newDate, setNewDate] = useState(null);

  const handleAction = (type) => {
    setActionType(type);
    if (type === 'increaseAmount' || type === 'reduceAmount') {
      setNewAmount(data.amount);
    } else if (type === 'extendDate' || type === 'renewLoan') {
      setNewDate(moment(data.finalDueDate));
    }
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      setIsModalVisible(false);
      let updatedData = {};
      if (actionType === 'increaseAmount' && newAmount !== null) {
        updatedData.amount = newAmount;
      } else if (actionType === 'extendDate' && newDate !== null) {
        updatedData.finalDueDate = newDate.toISOString();
      } else if (actionType === 'cancel') {
        updatedData.status = 'Cancelled';
      } else if (actionType === 'reduceAmount' && newAmount !== null) {
        updatedData.amount = newAmount;
      } else if (actionType === 'transferLoan') {
        // Handle transfer logic here
      } else if (actionType === 'releaseLoan') {
        updatedData.status = 'Released';
      } else if (actionType === 'amendLoanTerms') {
        // Handle amend terms logic here
      } else if (actionType === 'updateBeneficiary') {
        // Handle update beneficiary logic here
      } else if (actionType === 'renewLoan' && newDate !== null) {
        updatedData.finalDueDate = newDate.toISOString();
      }

      if (Object.keys(updatedData).length > 0) {
        await update(id, updatedData);
        message.success(`Loan ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} successfully`);
        revalidate(); // Revalidate to refresh logs and details without page refresh
      } else {
        message.warning('No changes detected');
      }
    } catch (error) {
      message.error(`Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} loan`);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <Space wrap>
          <Button type="primary" onClick={() => handleAction('extendDate')}>
            Extend Date
          </Button>
          <Button type="primary" onClick={() => handleAction('increaseAmount')}>
            Increase Amount
          </Button>
          <Button type="danger" onClick={() => handleAction('cancel')}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => handleAction('reduceAmount')}>
            Reduce Amount
          </Button>
          <Button type="primary" onClick={() => handleAction('transferLoan')}>
            Transfer Loan
          </Button>
          <Button type="primary" onClick={() => handleAction('releaseLoan')}>
            Release Loan
          </Button>
          <Button type="primary" onClick={() => handleAction('amendLoanTerms')}>
            Amend Loan Terms
          </Button>
          <Button type="primary" onClick={() => handleAction('updateBeneficiary')}>
            Update Beneficiary
          </Button>
          <Button type="primary" onClick={() => handleAction('renewLoan')}>
            Renew Loan
          </Button>
        </Space>
      </div>
      <Modal
        title={`Perform Action: ${actionType.replace(/([A-Z])/g, ' $1')}`}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {actionType === 'extendDate' || actionType === 'renewLoan' ? (
          <DatePicker
            // defaultValue={newDate}
            onChange={(date) => setNewDate(date)}
            style={{ width: '100%' }}
          />
        ) : (
          <LoanModalContent
            actionType={actionType}
            newAmount={newAmount}
            setNewAmount={setNewAmount}
            newDate={newDate}
            setNewDate={setNewDate}
            data={data}
          />
        )}
      </Modal>
    </>
  );
};

export default LoanFooterActions;
