import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Steps, message } from 'antd';
import moment from 'moment';

const { Step } = Steps;

const CreateLoanForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const next = () => {
    form.validateFields().then(values => {
      setFormData({ ...formData, ...values });
      setCurrentStep(currentStep + 1);
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFinish = (values) => {
    const combinedValues = {
      ...formData,
      ...values,
      loanIssueDate: formData.loanIssueDate ? formData.loanIssueDate.toISOString() : undefined,
      firstDueDate: formData.firstDueDate ? formData.firstDueDate.toISOString() : undefined,
      finalDueDate: values.finalDueDate ? values.finalDueDate.toISOString() : undefined,
    };
    onSubmit(combinedValues);
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Form.Item name="systemCode" label="System Code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="bank" label="Bank" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="englishDescription" label="English Description" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="arabicDescription" label="Arabic Description" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="loanIssueDate" label="Loan Issue Date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="loanType" label="Loan Type" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Details',
      content: (
        <>
          <Form.Item name="bankFacility" label="Bank Facility" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="firstDueDate" label="First Due Date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="vendor" label="Vendor" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="numberOfYears" label="Number Of Years" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="paymentAmount" label="Payment Amount" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="interestRate" label="Interest Rate" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="saborRate" label="Sabor Rate" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="loanReference" label="Loan Reference" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="presentValue" label="Present Value" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="loanStatus" label="Loan Status" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Additional Info',
      content: (
        <>
          <Form.Item name="effectiveAnnualInterestRate" label="Effective Annual Interest Rate" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="facilityType" label="Facility Type" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="finalDueDate" label="Final Due Date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="project" label="Project" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="numberOfPaymentsPerYear" label="Number of Payments Per Year" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="rePaymentAmount" label="Re-Payment Amount" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="downPayment" label="Down Payment" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="effectiveMonthlyInterestRate" label="Effective Monthly Interest Rate" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="remainingValue" label="Remaining Value" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="totalAmount" label="Total Amount" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <Form form={form} layout="vertical" onFinish={handleFinish} style={{ marginTop: 20 }}>
        {steps[currentStep].content}
        <div className="steps-action">
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? 'Creating...' : 'Submit'}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateLoanForm;
