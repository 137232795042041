// src/components/LCFooterActions.js
import React, { useState } from 'react';
import { Button, Modal, message, Space, Input } from 'antd';
import LCModalContent from './LCModalContent';
import { useUpdateLetterOfCredit } from '../../hooks/useLetterOfCredit';
import moment from 'moment';

const { TextArea } = Input;

const LCFooterActions = ({ id, data, revalidate }) => {
  const { update } = useUpdateLetterOfCredit();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState('');
  const [newAmount, setNewAmount] = useState(null);
  const [newDate, setNewDate] = useState(null);
  const [newTerms, setNewTerms] = useState('');
  const [newBeneficiary, setNewBeneficiary] = useState('');

  const handleAction = (type) => {
    setActionType(type);
    if (type === 'increaseAmount' || type === 'reduceAmount') {
      setNewAmount(0); // Initialize with 0 for user input
    } else if (type === 'extendDate' || type === 'renewLC') {
      setNewDate(moment(data.expiryDate));
    } else if (type === 'amendLCTerms') {
      setNewTerms(data.terms);
    } else if (type === 'updateBeneficiary') {
      setNewBeneficiary(data.beneficiary);
    }
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      setIsModalVisible(false);
      let updatedData = {};
      if (actionType === 'increaseAmount' && newAmount !== null) {
        if (data.balanceAmount !== undefined) {
          updatedData.balanceAmount = data.balanceAmount + newAmount;
        } else {
          updatedData.balanceAmount = data.amount + newAmount;
        }
      } else if (actionType === 'reduceAmount' && newAmount !== null) {
        if (data.balanceAmount !== undefined) {
          updatedData.balanceAmount = data.balanceAmount - newAmount;
        } else {
          updatedData.balanceAmount = data.amount - newAmount;
        }
      } else if (actionType === 'extendDate' && newDate !== null) {
        updatedData.expiryDate = newDate;
      } else if (actionType === 'cancel') {
        updatedData.status = 'Cancelled';
      } else if (actionType === 'transferLC') {
        // Handle transfer logic here
      } else if (actionType === 'releaseLC') {
        updatedData.status = 'Released';
      } else if (actionType === 'amendLCTerms' && newTerms !== '') {
        updatedData.terms = newTerms;
      } else if (actionType === 'updateBeneficiary' && newBeneficiary !== '') {
        updatedData.beneficiary = newBeneficiary;
      } else if (actionType === 'renewLC' && newDate !== null) {
        updatedData.expiryDate = newDate;
      }

      if (Object.keys(updatedData).length > 0) {
        await update(id, updatedData);
        message.success(`Letter of Credit ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} successfully`);
        revalidate();
      } else {
        message.warning('No changes detected');
      }
    } catch (error) {
      message.error(`Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} letter of credit`);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <Space wrap>
          <Button type="primary" onClick={() => handleAction('extendDate')}>
            Extend Date
          </Button>
          <Button type="primary" onClick={() => handleAction('increaseAmount')}>
            Increase Amount
          </Button>
          <Button type="danger" onClick={() => handleAction('cancel')}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => handleAction('reduceAmount')}>
            Reduce Amount
          </Button>
          <Button type="primary" onClick={() => handleAction('transferLC')}>
            Transfer LC
          </Button>
          <Button type="primary" onClick={() => handleAction('releaseLC')}>
            Release LC
          </Button>
          <Button type="primary" onClick={() => handleAction('amendLCTerms')}>
            Amend LC Terms
          </Button>
          <Button type="primary" onClick={() => handleAction('updateBeneficiary')}>
            Update Beneficiary
          </Button>
          <Button type="primary" onClick={() => handleAction('renewLC')}>
            Renew LC
          </Button>
        </Space>
      </div>
      <Modal
        title={`Perform Action: ${actionType.replace(/([A-Z])/g, ' $1')}`}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {actionType === 'amendLCTerms' ? (
          <TextArea
            placeholder="Enter new terms"
            value={newTerms}
            onChange={(e) => setNewTerms(e.target.value)}
            rows={4}
          />
        ) : actionType === 'updateBeneficiary' ? (
          <TextArea
            placeholder="Enter new beneficiary"
            value={newBeneficiary}
            onChange={(e) => setNewBeneficiary(e.target.value)}
            rows={4}
          />
        ) : (
          <LCModalContent
            actionType={actionType}
            newAmount={newAmount}
            setNewAmount={setNewAmount}
            newDate={newDate}
            setNewDate={setNewDate}
            data={data}
          />
        )}
      </Modal>
    </>
  );
};

export default LCFooterActions;
