import React from 'react';
import { Form, Input, InputNumber, DatePicker, Select, Row, Col, Card } from 'antd';
import moment from 'moment';

const { Option } = Select;

const LGFields = ({ data, isEditing, onFieldChange }) => {
  const handleFieldChange = (field, value) => {
    onFieldChange(field, value);
  };

  const lgTypeOptions = [
    { id: 1, descriptionEn: 'Bid bond' },
    { id: 2, descriptionEn: 'Performance bond' },
    { id: 3, descriptionEn: 'Advance payment bond' },
    { id: 4, descriptionEn: 'Retention bond' },
    { id: 5, descriptionEn: 'Survey bond' },
    { id: 6, descriptionEn: 'Financial bond' },
  ];

  const statusOptions = [
    { id: 'Open', descriptionEn: 'Open' },
    { id: 'Closed', descriptionEn: 'Closed' },
    { id: 'Pending', descriptionEn: 'Pending' },
  ];

  const renderField = (label, value, type, field) => {
    const displayValue = value || 'N/A';
    switch (type) {
      case 'number':
        return isEditing ? (
          <InputNumber
            min={0}
            defaultValue={value}
            onChange={(value) => handleFieldChange(field, value)}
            style={{ width: '100%' }}
          />
        ) : (
          displayValue
        );
      case 'date':
        return isEditing ? (
          <DatePicker
            defaultValue={moment(value)}
            onChange={(date) => handleFieldChange(field, date.toISOString())}
            style={{ width: '100%' }}
          />
        ) : (
          moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : 'Invalid Date'
        );
      case 'select':
        return isEditing ? (
          <Select
            defaultValue={value}
            onChange={(value) => handleFieldChange(field, value)}
            style={{ width: '100%' }}
          >
            {field === 'LGType'
              ? lgTypeOptions.map((option) => (
                  <Option key={option.id} value={option.descriptionEn}>
                    {option.descriptionEn}
                  </Option>
                ))
              : statusOptions.map((option) => (
                  <Option key={option.id} value={option.descriptionEn}>
                    {option.descriptionEn}
                  </Option>
                ))}
          </Select>
        ) : (
          displayValue
        );
      case 'text':
      default:
        return isEditing ? (
          <Input
            defaultValue={value}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            style={{ width: '100%' }}
          />
        ) : (
          displayValue
        );
    }
  };

  return (
    <Card bordered={false} style={{ boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', marginBottom: '24px' }}>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="LG Number"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('LG Number', data.LGNumber, 'text', 'LGNumber')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="LG Bank Number"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('LG Bank Number', data.LGBankNumber, 'text', 'LGBankNumber')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Origin Type"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Origin Type', data.originType, 'text', 'originType')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Origin ID"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Origin ID', data.origin, 'text', 'origin')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Account Type"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Account Type', data.accountType, 'text', 'accountType')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Account ID"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Account ID', data.account, 'text', 'account')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Status"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Status', data.status, 'select', 'status')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="LG Type"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('LG Type', data?.LGType, 'select', 'LGType')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Amount"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Amount', data.amount, 'number', 'amount')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Agreement Number"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Agreement Number', data.agreementNumber, 'text', 'agreementNumber')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Margin Amount"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Margin Amount', data.marginAmount, 'number', 'marginAmount')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Request Date"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Request Date', data.requestDate, 'date', 'requestDate')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Margin Percentage"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Margin Percentage', data.marginPercentage, 'number', 'marginPercentage')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Cancellation Reason"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Cancellation Reason', data.cancellationReason, 'text', 'cancellationReason')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Facility Status"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Facility Status', data.facilityStatus, 'text', 'facilityStatus')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Currency"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Currency', data.currency, 'text', 'currency')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Expense"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Expense', data.expense, 'number', 'expense')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Expiration Date"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Expiration Date', data.expirationDate, 'date', 'expirationDate')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Third Party"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Third Party', data.thirdParty, 'text', 'thirdParty')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Bank ID"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Bank ID', data.bankID ? data.bankID : '', 'text', 'bankID')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Created At"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {moment(data.createdAt).format('YYYY-MM-DD')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Updated At"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {moment(data.updatedAt).format('YYYY-MM-DD')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Beneficiary"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Beneficiary', data.beneficiary, 'text', 'beneficiary')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Terms"
              labelCol={{ style: { fontWeight: 'bold', color: '#1890ff' } }}
              wrapperCol={{ style: { color: '#333' } }}
            >
              {renderField('Terms', data.terms, 'text', 'terms')}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default LGFields;
