// src/pages/LetterOfCredit/LetterOfCreditDetails.js
import React, { useState, useEffect } from 'react';
import { Button, message, Card, Spin } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetLetterOfCredit, useUpdateLetterOfCredit } from '../../hooks/useLetterOfCredit';
import LCFields from '../../components/LC/LCFields';
import LCFooterActions from '../../components/LC/LCFooterActions';
import LCLogList from '../../components/LC/LCLogList';

const LetterOfCreditDetails = () => {
  const { id } = useParams();
  const { data, loading, error, revalidate } = useGetLetterOfCredit(id);
  const { update } = useUpdateLetterOfCredit();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});

  useEffect(() => {
    if (data) {
      const { createdAt, updatedAt, ...editableData } = data;  // Exclude createdAt and updatedAt
      setEditableData(editableData);
      console.log('Editable data set:', editableData);
    }
  }, [data]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    message.error('Failed to fetch letter of credit details');
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await update(id, editableData);
      message.success('Letter of Credit updated successfully');
      setIsEditing(false);
      revalidate();
    } catch (error) {
      message.error('Failed to update letter of credit');
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    const { createdAt, updatedAt, ...editableData } = data;  // Exclude createdAt and updatedAt
    setEditableData(editableData);
  };

  const handleFieldChange = (field, value) => {
    setEditableData({ ...editableData, [field]: value });
  };

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <Button onClick={() => navigate(-1)} style={{ marginBottom: 16 }}>
        Back to List
      </Button>
      <Card
        title="Letter of Credit Details"
        extra={
          !isEditing ? (
            <Button onClick={handleEdit}>Edit</Button>
          ) : (
            <Button onClick={handleSave} type="primary" style={{ marginRight: 8 }}>
              Save
            </Button>
          )
        }
        bordered={false}
        style={{ boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)' }}
      >
        <LCFields data={isEditing ? editableData : data} isEditing={isEditing} onFieldChange={handleFieldChange} />
        {isEditing && (
          <Button onClick={handleCancelEdit} style={{ marginTop: 16 }}>
            Cancel
          </Button>
        )}
        {!isEditing && (
          <LCFooterActions id={id} data={data} revalidate={revalidate} />
        )}
      </Card>
      <Card title="Change History" bordered={false} style={{ marginTop: 24, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)' }}>
        <LCLogList lcId={id} />
      </Card>
    </div>
  );
};

export default LetterOfCreditDetails;
