import React, { useState } from 'react';
import { Button, Modal, message, Space, Input } from 'antd';
import LGModalContent from './LGModalContent';
import { useUpdateLetterOfGuarantee } from '../../hooks/useLetterOfGuarantee';
import moment from 'moment';

const { TextArea } = Input;

const LGFooterActions = ({ id, data, revalidate }) => {
  const { update } = useUpdateLetterOfGuarantee();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState('');
  const [newAmount, setNewAmount] = useState(null);
  const [newDate, setNewDate] = useState(null);
  const [newTerms, setNewTerms] = useState('');
  const [newBeneficiary, setNewBeneficiary] = useState('');

  const handleAction = (type) => {
    setActionType(type);
    if (type === 'increaseAmount' || type === 'reduceAmount') {
      setNewAmount(data.amount);
    } else if (type === 'extendDate' || type === 'renewLG') {
      setNewDate(moment(data.expirationDate));
    } else if (type === 'amendLGTerms') {
      setNewTerms(data.terms);
    } else if (type === 'updateBeneficiary') {
      setNewBeneficiary(data.beneficiary);
    }
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      setIsModalVisible(false);
      let updatedData = {};
      if (actionType === 'increaseAmount' && newAmount !== null) {
        updatedData.amount = newAmount;
      } else if (actionType === 'extendDate' && newDate !== null) {
        updatedData.expirationDate = newDate.toISOString();
      } else if (actionType === 'cancel') {
        updatedData.status = 'Cancelled';
      } else if (actionType === 'reduceAmount' && newAmount !== null) {
        updatedData.amount = newAmount;
      } else if (actionType === 'transferLG') {
        // Handle transfer logic here
      } else if (actionType === 'releaseLG') {
        updatedData.status = 'Released';
      } else if (actionType === 'amendLGTerms' && newTerms !== '') {
        updatedData.terms = newTerms;
      } else if (actionType === 'updateBeneficiary' && newBeneficiary !== '') {
        updatedData.beneficiary = newBeneficiary;
      } else if (actionType === 'renewLG' && newDate !== null) {
        updatedData.expirationDate = newDate.toISOString();
      }

      if (Object.keys(updatedData).length > 0) {
        await update(id, updatedData);
        message.success(`Letter of Guarantee ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} successfully`);
        revalidate();
      } else {
        message.warning('No changes detected');
      }
    } catch (error) {
      message.error(`Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} letter of guarantee`);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <Space wrap>
          <Button type="primary" onClick={() => handleAction('extendDate')}>
            Extend Date
          </Button>
          <Button type="primary" onClick={() => handleAction('increaseAmount')}>
            Increase Amount
          </Button>
          <Button type="danger" onClick={() => handleAction('cancel')}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => handleAction('reduceAmount')}>
            Reduce Amount
          </Button>
          <Button type="primary" onClick={() => handleAction('transferLG')}>
            Transfer LG
          </Button>
          <Button type="primary" onClick={() => handleAction('releaseLG')}>
            Release LG
          </Button>
          <Button type="primary" onClick={() => handleAction('amendLGTerms')}>
            Amend LG Terms
          </Button>
          <Button type="primary" onClick={() => handleAction('updateBeneficiary')}>
            Update Beneficiary
          </Button>
          <Button type="primary" onClick={() => handleAction('renewLG')}>
            Renew LG
          </Button>
        </Space>
      </div>
      <Modal
        title={`Perform Action: ${actionType.replace(/([A-Z])/g, ' $1')}`}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {actionType === 'amendLGTerms' ? (
          <TextArea
            placeholder="Enter new terms"
            value={newTerms}
            onChange={(e) => setNewTerms(e.target.value)}
            rows={4}
          />
        ) : actionType === 'updateBeneficiary' ? (
          <TextArea
            placeholder="Enter new beneficiary"
            value={newBeneficiary}
            onChange={(e) => setNewBeneficiary(e.target.value)}
            rows={4}
          />
        ) : (
          <LGModalContent
            actionType={actionType}
            newAmount={newAmount}
            setNewAmount={setNewAmount}
            newDate={newDate}
            setNewDate={setNewDate}
            data={data}
          />
        )}
      </Modal>
    </>
  );
};

export default LGFooterActions;
