import React, { useState } from 'react';
import { Form, Input, Button, Select, DatePicker, InputNumber, Steps, message } from 'antd';
import { useCreateLetterOfGuarantee } from '../../hooks/useLetterOfGuarantee';

const { Step } = Steps;
const { Option } = Select;

const statusOptions = [
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Released', value: 'Released' },
];

const CreateLGForm = () => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [basicInfo, setBasicInfo] = useState({});
  const [details, setDetails] = useState({});
  const [datesAndMore, setDatesAndMore] = useState({});
  const { create, isLoading } = useCreateLetterOfGuarantee();

  const next = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (currentStep === 0) {
        setBasicInfo(values);
      } else if (currentStep === 1) {
        setDetails(values);
      }
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinish = async (values) => {
    try {
      setDatesAndMore(values);
      const data = {
        ...basicInfo,
        ...details,
        ...values,
        requestDate: values.requestDate.toISOString(),
        expirationDate: values.expirationDate.toISOString(),
      };
      console.log(data);
      await create(data);
      form.resetFields();
      message.success('Letter of Guarantee created successfully!');
    } catch (error) {
      message.error('Failed to create Letter of Guarantee: ' + error.message);
    }
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Form.Item name="LGNumber" label="LG Number" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="LGBankNumber" label="LG Bank Number" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="bankID" label="Bank ID" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="originType" label="Origin Type" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="origin" label="Origin ID" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="accountType" label="Account Type" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="account" label="Account ID" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Details',
      content: (
        <>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              {statusOptions.map(option => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="LGType" label="LG Type" rules={[{ required: true }]}>
            <Select>
              <Option value="Bid bond">Bid bond</Option>
              <Option value="Performance bond">Performance bond</Option>
              <Option value="Advance payment bond">Advance payment bond</Option>
              <Option value="Retention bond">Retention bond</Option>
              <Option value="Surety bond">Surety bond</Option>
              <Option value="Financial bond">Financial bond</Option>
            </Select>
          </Form.Item>
          <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="agreementNumber" label="Agreement Number" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="marginAmount" label="Margin Amount" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="marginPercentage" label="Margin %" rules={[{ required: true }]}>
            <InputNumber min={0} max={100} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="beneficiary" label="Beneficiary">
            <Input />
          </Form.Item>
          <Form.Item name="terms" label="Terms">
            <Input.TextArea rows={4} />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Dates & More',
      content: (
        <>
          <Form.Item name="requestDate" label="Request Date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="expirationDate" label="Expiration Date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="facilityStatus" label="Facility Status" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="currency" label="Currency" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="expense" label="Expense" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="thirdParty" label="Third Party">
            <Input />
          </Form.Item>
          <Form.Item name="cancellationReason" label="Cancellation Reason">
            <Input />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <Form form={form} layout="vertical" onFinish={onFinish} style={{ marginTop: 20 }}>
        {steps[currentStep].content}
        <div className="steps-action">
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isLoading ? 'Creating...' : 'Submit'}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateLGForm;
